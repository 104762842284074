export const strict = false
import marks from '~/apollo/queries/marks'
import settings from '~/apollo/queries/settings'

export const state = () => ({
    site_id: null,
    domain: '',
})
export const getters = {
    site_id: (state) => {
        return state.site_id
    },
    // isMoscow:(state) =>{
    //    return state.site_id === 21 || state.site_id === 28
    // },
    domain: (state) => {
        return state.domain
    },
}
export const actions = {
    async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {
        let site_id = 44;
        commit('SET_SITE_ID', $config.site_id);
        commit('SET_DOMAIN', $config.domain);

        let client = app.apolloProvider.defaultClient
        // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
        commit('banks/SET_PERCENT', $config.default_percent)
        // // TODO танцы с бубнами
        // commit('filters/filters/SET_LOADING_RANGE', false)


        // if (req.headers.host === 'cars-lider.ru') {
        //     commit('SET_SITE_ID', $config.site_id_mirror1);
        //     commit('SET_DOMAIN', $config.domain_mirror1);
        // } else if (req.headers.host === 'xn----7sbnenr2b4b.xn--p1ai') {
        //     commit('SET_SITE_ID', $config.site_id_mirror2);
        //     commit('SET_DOMAIN', $config.domain_mirror2);
        // } else if (req.headers.host === 'xn----7sbbgkgtx0ayq0d.xn--p1ai') {
        //     commit('SET_SITE_ID', $config.site_id_mirror3);
        //     commit('SET_DOMAIN', $config.domain_mirror3);
        // } else if (req.headers.host === 'xn--91-8kcaajc8d8bye.xn--p1ai') {
        //     commit('SET_SITE_ID', $config.site_id_mirror4);
        //     commit('SET_DOMAIN', $config.domain_mirror4);
        // }


        //TODO НАСТРОЙКИ САЙТА
        let currentSettings = {}
        let settingsResponse = await client.query(
            {
                query: settings,
                variables: { site_id: state.site_id }
            })
        settingsResponse.data.settings.settings.map(value => {
            currentSettings[value.key] = value.value
        })
        const settingsGap = {
            ym: '66666666666666666',
            city: 'хз где',
            gtag: 'UA-158125162-1',
            email: 'pochta@rambler.auf',
            gtag1: 'AW-1111111111111111111111111',
            phone: '+7 (000) 000-00-00',
            domain: 'https://varshavka91.ru',
            phone1: '',
            phone2: '',
            address: 'г. Какой-то, Какое-то шоссе, д. 9 этажей, стр. из кипича',
            goal_id: '236519423',
            in_city: 'где-то ',
            vk_pixel: '1111111111111111',
            legal_inn: '111111111111111',
            legal_kpp: '1111111111111111',
            legal_name: 'ООО «ООО»',
            legal_ogrn: '1111111111111111',
            coordinates: '11.650830, 11.621112',
            sale_credit: 'до 200 000',
            soc_youtube: 'фыв',
            dealer_title: '«Сайт»',
            mytarget_alt: '3240641',
            sale_tradein: 'до 250 000',
            legal_address: '117556, город какой-то, Какое-то ш., д. большой, стр. тоже',
            soc_instagram: 'фыв',
            work_schedule: 'Ежедневно с 9:00 до 21:00',
            credit_approve: 'По паспорту и правам',
            credit_percent: '4.9%',
            max_sum_credit: '5 000 000',
            min_sum_credit: '50 000',
            credit_repayment: 'Без штрафов',
            first_installment: '0%',
            max_period_credit: '7 лет',
            min_period_credit: '6 месяцев',
            credit_max_percent: '16.5%',
            credit_registration: '30 минут',
            installment_percent: '0%'
        }
        commit('settings/settings/SET_SETTINGS', currentSettings)
        // commit('settings/settings/SET_SETTINGS', settingsGap)


        //   commit('SET_DOMAIN', currentSettings.domain.slice(0, -1));



        // TODO ПОЛУЧАЮ СПИСОК МАРОК
        let response = await client.query(
            {
                query: marks,
                variables: { site_id: state.site_id, category: 'cars' }
            })
        commit('marks/marks/SET_ALL_MARKS', response.data.marks)
    },
    async request({ state }, { query, variables }) {
        let assignVariables = {
            site_id: state.site_id
        }
        let client = this.app.apolloProvider.defaultClient
        let params = { ...assignVariables, ...variables }
        return await client.query(
            {
                query: query,
                variables: this.$removeEmptyObjects(params),
                fetchPolicy: 'no-cache'
            })
    }
}
export const mutations = {
    SET_SITE_ID(state, data) {
        state.site_id = data
    },
    SET_DOMAIN(state, data) {
        state.domain = data
    },
}
