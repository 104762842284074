import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _574423c8 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _3da29cf2 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _5d72f1dd = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _d96d8330 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _3f20d98e = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _0efeb551 = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _c96bf790 = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _4b012c12 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _5849051c = () => interopDefault(import('../pages/Installments/index.vue' /* webpackChunkName: "pages/Installments/index" */))
const _1f199cfa = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _2e1cfed4 = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _0002451e = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _11033ade = () => interopDefault(import('../pages/Warranty.vue' /* webpackChunkName: "pages/Warranty" */))
const _7bad6bc6 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _00ec42a6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1bde6c8a = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _46ff2658 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _382d6d17 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _30ddd963 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _574423c8,
    name: "About"
  }, {
    path: "/Banks",
    component: _3da29cf2,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _5d72f1dd,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _d96d8330,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _3f20d98e,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _0efeb551,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _c96bf790,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _4b012c12,
    name: "Favorites"
  }, {
    path: "/Installments",
    component: _5849051c,
    name: "Installments"
  }, {
    path: "/Privacy",
    component: _1f199cfa,
    name: "Privacy"
  }, {
    path: "/Reviews",
    component: _2e1cfed4,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _0002451e,
    name: "Thanks"
  }, {
    path: "/Warranty",
    component: _11033ade,
    name: "Warranty"
  }, {
    path: "/Credit/:bank",
    component: _7bad6bc6,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _00ec42a6,
    name: "index"
  }, {
    path: "/:category",
    component: _1bde6c8a,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _46ff2658,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _382d6d17,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _30ddd963,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
